import Vue from "vue";
import VueRouter from "vue-router";

// const Navbar = () => import("@/views/nav/Index.vue");
// const Layout = () => import("@/views/layout/Index.vue");
const Home = () => import("@/views/home/Home.vue");
const MyBuy = () => import("@/views/items/my-buy.vue");
const MySell = () => import("@/views/items/my-sell.vue");
const MyItems = () => import("@/views/items/my-items.vue");

const News = () => import("@/views/home/News.vue");

Vue.use(VueRouter);

const routes = [
  
      {
        path: "/",
        component: Home,
        name: "home",
      },
      {
        path: "/news",
        component: News,
        name: "news",
      },
      {
        path: '/my-buy',
        component: MyBuy,
        name: "MyBuy"
      },
      {
        path: '/my-sell',
        component: MySell,
        name: "MySell"
      },
      {
        path: '/my-items',
        component: MyItems,
        name: "MyItems"
      }
    
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
