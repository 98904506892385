import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import '@/styles/global.css'
import router from './router'
import 'normalize.css'

Vue.config.productionTip = false

new Vue({
  el: "#app",
  router,
  render: h => h(App),
})
